import React, { useState, useEffect, useRef, useContext } from 'react'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import ContextStates from '../context/contextStates'
import SubirImagenes from './SubirImagenes'
import FormRolesUsuario from './FormRolesUsuario'
import { Url } from './Url'

const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
}

const FEditUsuario = () => {
    const [ tiposUsuarios, setTiposUsuarios ] = useState([])
    const [ opcionUsuario, setOpcionUsuario ] = useState(1)
    const [ costo, setCosto ] = useState(0)
    const [ especialidades, setEspecialidades ] = useState([])
    const [ especialidad, setEspecialidad ] = useState(0)
    const { idUsuarioNuevo, 
        setIdUsuarioNuevo, 
        usuarioSelected, 
        setUsuarioSelected, 
        tipoUsuario, 
        setTipoUsuario, 
        setOpenVista, 
        setListadoRoles } = useContext(ContextStates);

    let tipoImagen = "usuarios";
    let idHtml = "imagenesUsuario";
    const childRef = useRef()

    useEffect(()=>{
        const consultaTipos = async () => {
            const url = Url+'api/tUserList.php';
            const resultado = await axios.get(url);
            setTiposUsuarios(resultado.data.results);
            setTipoUsuario(usuarioSelected.tipo)
        }
        consultaTipos()
    },[])
    useEffect(()=>{
        const consultaEsp = async () => {
            const url = Url+'api/espList.php';
            const resultado = await axios.get(url);
            //console.log(resultado)
            setEspecialidades(resultado.data.results);
            setEspecialidad(usuarioSelected.idespecialidad)
        }
        consultaEsp()
    },[])
    useEffect(()=>{
        const actualizarDatos = () => {
            setCosto(usuarioSelected.costo)
        }
        actualizarDatos()
    },[usuarioSelected.id])

    useEffect(()=>{
        const consultaRoles = async () => {
            const formData=new FormData()
            formData.append('idusuario',usuarioSelected.id);
            try{
                const response = await axios({
                    url: Url+'api/rolesusuario.php',
                    method: 'POST',
                    data: formData,
                })
                setListadoRoles(response.data.results.roles)
            } catch (e) {
                console.log(e)
            }
        }
        consultaRoles()
    },[usuarioSelected.id])




    const eliminarImagen = async () => {
        const formData=new FormData()
        formData.append('t',"usuarios");
        formData.append('id',usuarioSelected.id);
        
        let oData={...usuarioSelected};
        oData.imagen=null;
        setUsuarioSelected(oData);
        try{
            const response = await axios({
                url: Url+'api/deleteimages.php',
                method: 'POST',
                data: formData,
            })
            setIdUsuarioNuevo(response.data.results.id);
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(()=>{
        if (idUsuarioNuevo!==0)
        {
            if (typeof childRef.current!=="undefined")
            {
                childRef.current.handleSubmit();
            }
        }
    },[idUsuarioNuevo])

    const formik = useFormik({
        initialValues:{
            apellido: usuarioSelected.apellido,
            nombre: usuarioSelected.nombre,
            documento: usuarioSelected.documento,
            mail: usuarioSelected.mail,
            nombreusuario: usuarioSelected.nombreusuario,
            password: usuarioSelected.password
        },
        validationSchema: Yup.object({
            apellido: Yup.string()
                    .required("El apellido es necesario"),
            nombre: Yup.string()
                    .required("El nombre es necesario"),
            documento: Yup.string(),
            mail: Yup.string()
                    .required("El mail es necesario"),
            
            password: Yup.string(),
            
        }),
        onSubmit: async (valores,{resetForm}) => {
            const formData=new FormData()
            formData.append('id',usuarioSelected.id);
            formData.append('apellido',valores.apellido);
            formData.append('nombre',valores.nombre);
            formData.append('mail',valores.mail);
            formData.append('nombreusuario',valores.nombreusuario);
            formData.append('password',valores.password);
            formData.append('especialidad',especialidad);
            formData.append('costo',costo);
            formData.append('tipo',tipoUsuario);
            try{
                const response = await axios({
                    url: Url+'api/usuariosSet.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(response)
                setIdUsuarioNuevo(response.data.results.idusuario);
                resetForm({ values: ''});
                setOpenVista(false);
            } catch (e) {
                console.log(e)
            }
        }
    });
    return (
    <form
        className="w-full mx-auto p-5 rounded-2xl"
        onSubmit={formik.handleSubmit}
    >
        <div className='flex'>
            <button
                type='button'
                className={classNames(opcionUsuario===1 && ("bg-slate-300 rounded-lg") ,"mt-5 mb-1 mx-3 text-gray-700 p-2 text-xl text-center")}
                onClick={()=>setOpcionUsuario(1)}
            >Datos del Usuario</button>
            <button
                type='button'
                className={classNames(opcionUsuario===2 && ("bg-slate-300 rounded-lg") ,"mt-5 mb-1 mx-3 text-gray-700 p-2 text-xl text-center")}
                onClick={()=>setOpcionUsuario(2)}
            >Roles del Usuario</button>
        </div>
        { opcionUsuario===1 ? (
            <>
            <div className="transition-all w-full sm:p-3">
                <div className="sm:items-start">
                    <label htmlFor="apellido" className="block mt-5 text-gray-600 uppercase font-bold">Apellido</label>
                    <input 
                        className="py-2 px-3 w-full rounded bg-slate-300" 
                        id="apellido" 
                        placeholder="Ingrese el apellido" 
                        type="text" 
                        value={formik.values.apellido}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoFocus
                    />
                    { formik.touched.apellido && formik.errors.apellido ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.apellido}</p>
                        </div>
                    ) : null }
                </div>
                <div className="sm:items-start">
                    <label htmlFor="nombre" className="block mt-5 text-gray-600 uppercase font-bold">Nombre</label>
                    <input 
                        className="py-2 px-3 w-full rounded bg-slate-300" 
                        id="nombre" 
                        placeholder="Ingrese el nombre" 
                        type="text" 
                        value={formik.values.nombre}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoFocus
                    />
                    { formik.touched.nombre && formik.errors.nombre ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.nombre}</p>
                        </div>
                    ) : null }
                </div>
                <div className="sm:items-start">
                    <label htmlFor="documento" className="block mt-5 text-gray-600 uppercase font-bold">Documento</label>
                    <input 
                        className="py-2 px-3 w-full rounded bg-slate-300" 
                        id="documento" 
                        placeholder="Ingrese el Nº de documento" 
                        type="text" 
                        value={formik.values.documento}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoFocus
                    />
                    { formik.touched.documento && formik.errors.documento ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.documento}</p>
                        </div>
                    ) : null }
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="mail" className="block text-gray-600 uppercase font-bold">Email</label>
                    <input 
                        className="py-2 px-3 w-full rounded bg-slate-300" 
                        id="mail" 
                        placeholder="Ingrese el email" 
                        type="email" 
                        value={formik.values.mail}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    { formik.touched.mail && formik.errors.mail ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.mail}</p>
                        </div>
                    ) : null }
                </div>
                <div className="sm:items-start">
                    <label htmlFor="nombreusuario" className="block mt-5 text-gray-600 uppercase font-bold">Nombre de Usuario</label>
                    <input 
                        className="py-2 px-3 w-full rounded bg-slate-300" 
                        id="nombreusuario" 
                        placeholder="Ingrese un nombre de usuario" 
                        type="text" 
                        value={formik.values.nombreusuario}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    { formik.touched.nombreusuario && formik.errors.nombreusuario ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.nombreusuario}</p>
                        </div>
                    ) : null }
                </div>
                <div className="sm:items-start">
                    <label htmlFor="password" className="block mt-5 text-gray-600 uppercase font-bold">Contraseña</label>
                    <input 
                        className="py-2 px-3 w-full rounded bg-slate-300" 
                        id="password" 
                        placeholder="Ingrese una contraseña" 
                        type="password" 
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    { formik.touched.password && formik.errors.password ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.password}</p>
                        </div>
                    ) : null }
                </div>
                <div className="sm:items-start mt-5">
                    <label htmlFor="sTipo" className="block text-gray-600 uppercase font-bold">Tipo de Usuario</label>
                    <select 
                        className='py-2 px-3 rounded bg-slate-300' id='sTipo' onChange={(e)=>setTipoUsuario(e.target.value)}
                        value={tipoUsuario}
                    >
                        { typeof tiposUsuarios !== "undefined" ? (
                            tiposUsuarios.map(item => (
                                <option key={item.id} value={item.id}>{item.nombre}</option>))
                        ) : null }
                    </select>
                </div>
                { tipoUsuario==='2' && (
                    <>
                    <div className="sm:items-start mt-5">
                        <label htmlFor="sEspecialidad" className="block text-gray-600 uppercase font-bold">Especialidad</label>
                        <select className='py-2 px-3 rounded bg-slate-300' value={especialidad} id='sEspecialidad' onChange={(e)=>setEspecialidad(e.target.value)}>
                            { typeof especialidades !== "undefined" ? (
                                especialidades.map(item => (
                                    <option key={item.id} value={item.id}>{item.nombre}</option>))
                            ) : null }
                        </select>
                    </div>
                    <div className="sm:items-start mt-5">
                        <label htmlFor="mail" className="block text-gray-600 uppercase font-bold">Costo por hora</label>
                        <input 
                            className="py-2 px-3 w-full rounded bg-slate-300" 
                            id="costo" 
                            placeholder="Costo por hora" 
                            type="number" 
                            value={costo}
                            onChange={(e)=>setCosto(e.target.value)}
                        />
                    </div>
                    </>
                )
                
                }
                <div className="pb-10">
                    { usuarioSelected.imagen ? (
                        <div className='lg:w-1/6 md:w-1/4 w-1/2 p-2'>
                            <img src={`${Url}${usuarioSelected.imagen}`}/>
                            <button
                                type='button'
                                className='w-full bg-red-500 text-white rounded-b-md'
                                onClick={()=>eliminarImagen()}
                            >Eliminar</button>
                        </div>
                    ): (
                        <SubirImagenes 
                            id={usuarioSelected.id} 
                            tipo={tipoImagen} 
                            ref={childRef}
                            idHtml={idHtml}
                        />
                    )}
                </div>
            </div>
            <div className="pb-1">
                <div className="w-full rounded-lg px-3 flex justify-center">
                    <input
                        type="submit"
                        className="w-1/3 sm:w-1/4 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                        value="Guardar Datos"
                    />
                    <button
                        type="button"
                        className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                        onClick={() => setOpenVista(false)}
                    >
                        Salir
                    </button>
                </div>
            </div>
            </>
        ):(
            <div><FormRolesUsuario/></div>
        )}
    </form>               
    )
}

export default FEditUsuario