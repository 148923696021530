import { Link } from 'react-router-dom';
import Layout from '../components/layout';

const Index = () => {


    return (
        <Layout
            pagina="Inicio"
        >
			<div className="w-full flex-1 bg-slate-100">
				<h1 className="mb-5 pt-5 text-gray-700 text-3xl text-center">Sistema de control de gastos</h1>
                <div className="w-full bg-white p-5 md:flex-1 min-h-screen">
                    <div className='md:flex'>
                        <div className="w-full md:w-1/3 p-5 mt-5 md:mx-5 bg-indigo-400 hover:bg-indigo-300 shadow-md hover:shadow-xl hover:cursor-pointer rounded-xl">
                            <Link
                                className="w-full md:w-1/3 mt-5 mx-5"
                                to={'/gastos'}
                            >
                                <div className="">
                                    <h3 className="text-center mt-3 text-gray-100 text-2xl uppercase">Gastos</h3>
                                </div>
                            </Link>
                        </div>
                        <div className="w-full md:w-1/3 p-5 mt-5 md:mx-5 bg-indigo-400 hover:bg-indigo-300 shadow-md hover:shadow-xl hover:cursor-pointer rounded-xl">
                            <Link
                                className="w-full md:w-1/3 mt-5 mx-5"
                                to={'/reporting'}
                            >
                                <div className="">
                                    <h3 className="text-center mt-3 text-gray-100 text-2xl uppercase">Reportes</h3>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Index