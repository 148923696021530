import React, { useContext } from 'react'
import axios from 'axios'
import ContextStates from '../context/contextStates'
import { Url } from './Url'

const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
}

const FormRolesUsuario = () => {
    const { usuarioSelected,setOpenVista,listadoRoles, setListadoRoles } = useContext(ContextStates);

    const actualizarRol = async (rol, estado) => {
        const formData=new FormData()
        formData.append('idusuario',usuarioSelected.id);
        formData.append('rol',rol);
        formData.append('estado',estado);
        try{
            const response = await axios({
                url: Url+'api/actualizarRol.php',
                method: 'POST',
                data: formData,
            })
        } catch (e) {
            console.log(e)
        }
    }

    const handleChange = (e) => {
        let rData=[...listadoRoles];
        rData.forEach(element => {

            if (element.id===e.id)
            {
                if (!element.habilitado)
                {
                    element.habilitado=true;
                    actualizarRol(element.id,1);
                }
                else
                {
                    element.habilitado=false;
                    actualizarRol(element.id,0);
                }
            }
        });
        return (setListadoRoles(rData))

    }

    return (
    <div className="w-full mx-auto p-5 rounded-2xl">
        <div className="pb-1">
            <div>
                {
                    typeof listadoRoles !== 'undefined' ? (
                        listadoRoles.map((item) => (
                            <div className='flex'>
                                <input type='checkbox' id={item.id} value={item.id}  onChange={(e)=>handleChange(e.target)} checked={item.habilitado} /><label className='ml-3' htmlFor={item.id}>{item.nombre}</label>
                            </div>
                        ))
                    ):(null)
                }
            </div>
            <div className="w-full rounded-lg px-3 flex justify-center">
                <button
                    type="button"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenVista(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    </div>               
    )
}

export default FormRolesUsuario