import React, { useState, useContext } from 'react'
import Layout from '../components/layout';
import ContextStates from '../context/contextStates';
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai'
import ListGastos from '../components/listGastos';

const Gastos = () => {
	const [tab, setTab] = useState(1);
	const { setOpenAlta, setFormAlta } = useContext(ContextStates);

	const Alta = (opc) => {
		switch (opc) {
			case 1:
				setFormAlta(1);
				break;
			case 2:
				setFormAlta(2);
				break;
		}
		setOpenAlta(true)
	}

	return (
		<Layout
			pagina="Gastos"
		>
			<div className="w-full flex-1 bg-slate-100">
				<h1 className="mb-5 pt-5 text-gray-700 text-3xl text-center">Gastos</h1>

				<div className='w-full mt-2 bg-white'>
					<div className='w-full h-16 py-2 px-10 bg-white-400 flex'>
						<button
							className='bg-indigo-600 hover:bg-indigo-400 shadow-lg p-3 rounded-md text-white uppercase flex'
							onClick={() => Alta(1)}
						>
							<AiOutlinePlus className='text-2xl mr-2' />
							Agregar Gasto
						</button>
					</div>
					<div className='w-full h-screen p-10 bg-white overflow-scroll'>
						<ListGastos />
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default Gastos