import React, { useState, useEffect, useContext, useRef } from 'react'
import ContextStates from '../../context/contextStates'
import { AiOutlinePlus } from 'react-icons/ai'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import { Url } from '../Url'
import SubirImagenes from '../SubirImagenes'

const FormGasto = () => {
    const [tipos, setTipos] = useState([])
    const [tipo, setTipo] = useState(0)
    const [monedas, setMonedas] = useState([])
    const [moneda, setMoneda] = useState(0)
    const { setOpenAlta, setFormAlta, idGasto, setIdGasto } = useContext(ContextStates)

    let tipoImagen = "gastos";
    let idHtml = "imagenesGasto";
    const childRef = useRef()

    useEffect(() => {
        const checkTiposGastos = async () => {
            const formData = new FormData()
            formData.append('id', 1);
            try {
                const response = await axios({
                    url: Url + 'api/tGastoList.php',
                    method: 'POST',
                    data: formData,
                })
                setTipos(response.data.results)
                setTipo(response.data.results[0].id)
            } catch (e) {
                console.log(e)
            }
        }
        checkTiposGastos()
    }, [])

    useEffect(() => {
        const checkMonedas = async () => {
            const formData = new FormData()
            formData.append('id', 1);
            try {
                const response = await axios({
                    url: Url + 'api/monedasList.php',
                    method: 'POST',
                    data: formData,
                })
                setMonedas(response.data.results)
                setMoneda(response.data.results[0].id)
            } catch (e) {
                console.log(e)
            }
        }
        checkMonedas()
    }, [])

    useEffect(() => {
        if (idGasto !== 0) {
            childRef.current.handleSubmit();
        }
    }, [idGasto])

    const formik = useFormik({
        initialValues: {
            descripcion: '',
            importe: '',
            fecha: '',
            numerocpbt: ''
        },
        validationSchema: Yup.object({
            descripcion: Yup.string()
                .required("Debe ingresar una descripción del gasto"),
            importe: Yup.string()
                .required("Debe ingresar un importe"),
            fecha: Yup.string()
                .required("Debe ingresar una fecha"),
            numerocpbt: Yup.string()
                .required("Debe ingresar un número de comprobante o factura"),
        }),
        onSubmit: async (valores, { resetForm }) => {
            const formData = new FormData()
            formData.append('idtipo', tipo);
            formData.append('descripcion', valores.descripcion);
            formData.append('fecha', valores.fecha);
            formData.append('importe', valores.importe);
            formData.append('moneda', moneda);
            formData.append('numerocpbt', valores.numerocpbt);
            try {
                const response = await axios({
                    url: Url + 'api/gastoAlta.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(response)
                setIdGasto(response.data.results.id)
                resetForm({ values: '' });
            } catch (e) {
                console.log(e)
            }
        }
    });

    const altaTipo = () => {
        setFormAlta(2);
        setOpenAlta(true)
    }

    return (
        <form
            className="w-full mx-auto p-5 rounded-2xl"
            onSubmit={formik.handleSubmit}
        >
            <div className="transition-all w-full sm:p-3">
                <h2 className='text-3xl uppercase mb-3 text-center'>Nuevo Gasto</h2>
                <div className="sm:items-start mt-5">
                    <label htmlFor="importe" className="block text-gray-600 uppercase font-bold">Descripción</label>
                    <input
                        className="py-1 px-3 w-full rounded bg-slate-300"
                        id="descripcion"
                        placeholder="Descripción"
                        type="text"
                        value={formik.values.descripcion}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.descripcion && formik.errors.descripcion ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.descripcion}</p>
                        </div>
                    ) : null}
                </div>
                <div className="sm:items-start mt-3">
                    <label htmlFor="importe" className="block text-gray-600 uppercase font-bold">Importe</label>
                    <input
                        className="py-1 px-3 w-full rounded bg-slate-300"
                        id="importe"
                        placeholder="Importe del gasto"
                        type="text"
                        value={formik.values.importe}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.importe && formik.errors.importe ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.importe}</p>
                        </div>
                    ) : null}
                </div>
                <div className="sm:items-start mt-3">
                    <label htmlFor="numerocpbt" className="block text-gray-600 uppercase font-bold">Número de Comprobante o Factura</label>
                    <input
                        className="py-1 px-3 w-full rounded bg-slate-300"
                        id="numerocpbt"
                        placeholder="Nº de comprobante"
                        type="text"
                        value={formik.values.numerocpbt}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.numerocpbt && formik.errors.numerocpbt ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.numerocpbt}</p>
                        </div>
                    ) : null}
                </div>
                <div className="sm:items-start mt-3">
                    <label htmlFor="fecha" className="block text-gray-600 uppercase font-bold">Fecha</label>
                    <input
                        className="py-1 px-3 w-full rounded bg-slate-300"
                        id="fecha"
                        placeholder="Fecha"
                        type="date"
                        value={formik.values.date}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.fecha && formik.errors.fecha ? (
                        <div className='w-full block mb-2 text-red-500'>
                            <p>{formik.errors.fecha}</p>
                        </div>
                    ) : null}
                </div>
                <div className='my-3'>
                    <div className='flex'>
                        <div className='w-5/6'>
                            <label htmlFor="sTGasto" className="block text-gray-600 font-bold">Tipo de Gasto</label>
                            <select className='w-full p-2 rounded bg-slate-300 text-gray-700' id='sTGasto' onChange={(e) => setTipo(e.target.value)}>
                                {typeof tipos !== "undefined" && (
                                    tipos.map(item => (
                                        <option key={item.id} value={item.id}>{item.descripcion}</option>
                                    )))}
                            </select>
                        </div>
                        <div className='w-1/6'>
                            <button
                                className='w-full mx-auto p-2 bg-orange-400 text-white rounded-lg mt-6'
                                onClick={() => altaTipo()}
                            >
                                <AiOutlinePlus className='mx-auto font-bold text-xl' />
                            </button>
                        </div>
                    </div>
                </div>
                <div className='my-3'>
                    <label htmlFor="sMoneda" className="block text-gray-600 font-bold">Moneda</label>
                    <select className='w-full p-2 rounded bg-slate-300 text-gray-700' id='sMoneda' onChange={(e) => setMoneda(e.target.value)}>
                        {typeof monedas !== "undefined" && (
                            monedas.map(item => (
                                <option key={item.id} value={item.id}>{item.descripcion + ' - ' + item.abreviatura}</option>
                            )))}
                    </select>
                </div>
                <div className='my-3'>
                    <SubirImagenes
                        id={idGasto}
                        tipo={tipoImagen}
                        ref={childRef}
                        idHtml={idHtml}
                    />
                </div>

            </div>
            <div className="pb-1">
                <div className="w-full rounded-lg px-3 flex justify-center">
                    <input
                        type="submit"
                        className="w-1/3 sm:w-1/4 shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                        value="Guardar Datos"
                    />
                    <button
                        type="button"
                        className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                        onClick={() => setOpenAlta(false)}
                    >
                        Salir
                    </button>
                </div>
            </div>
        </form>)
}

export default FormGasto