import React, { useContext } from 'react'
import axios from 'axios'
import ContextStates from '../context/contextStates'


import { Url } from './Url'

const FDeleteUsuario = () => {
   
    const { setIdUsuarioNuevo,usuarioSelected, setOpenVista } = useContext(ContextStates);
    
    const eliminarUsuario = async () => {
        const formData=new FormData()
        formData.append('id',usuarioSelected.id);
        try{
            const response = await axios({
                url: Url+'api/usuariosDelete.php',
                method: 'POST',
                data: formData,
            })
            setIdUsuarioNuevo(response.data.results.idusuario);
            setOpenVista(false)
        } catch (e) {
            console.log(e)
        }
    }
 
    return (
    <div className='mt-10 mb-10 px-10'>
        <h3 className='text-2xl text-red-600 uppercase'>Eliminar Usuario</h3>
        <p className='text-xl text-gray-600'>Apellido: {usuarioSelected.apellido}</p>
        <p className='text-xl mt-4 text-gray-600'>Nombre: {usuarioSelected.nombre}</p>
        <p className='text-xl mt-4 text-gray-600'>Email: {usuarioSelected.mail}</p>
        
        <p className='text-2xl mt-10 text-center text-red-600'>¿Confirma que desea eliminar el Usuario?</p>
        <div className="w-full rounded-lg mt-3 px-3 flex justify-center">
            <button
                type="button"
                className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-red-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                onClick={() => eliminarUsuario()}
            >
                Eliminar
            </button>
            <button
                type="button"
                className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                onClick={() => setOpenVista(false)}
            >
                Salir
            </button>
        </div>
    </div>
    )
}

export default FDeleteUsuario