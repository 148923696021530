import React, { useState, useEffect, useContext } from 'react'
import Layout from '../components/layout';
import ContextStates from '../context/contextStates';
import Switch from "react-switch";
import axios from 'axios';
import { Url } from '../components/Url';
import BarChartGraph from '../components/charts/BarChartGraph';
import Datepicker from "react-tailwindcss-datepicker";

const Reporting = () => {
	const [gastos, setGastos] = useState([]);
	const [check, setCheck] = useState(false)
	const [dateValue, setDateValue] = useState({
		startDate: new Date(),
		endDate: new Date().setMonth(11)
	});


	const handleValueChange = (newValue) => {
		setDateValue(newValue);
	}


	useEffect(() => {

		const cantidadesOt = async () => {
			let group = 0;
			if (check)
				group = 1;
			const formData = new FormData()
			formData.append('group', group);
			formData.append('fechainicio', dateValue.startDate)
			formData.append('fechafin', dateValue.endDate)
			try {
				const response = await axios({
					url: Url + 'api/reportes.php',
					method: 'POST',
					data: formData,
				})
				setGastos(response.data.results)
			} catch (e) {
				console.log(e)
			}
		}
		cantidadesOt()
	}, [dateValue, check])

	return (
		<Layout
			pagina="Reportes"
		>
			<div className="w-full flex-1 bg-slate-100">
				<h1 className="mb-5 pt-5 text-gray-700 text-3xl text-center">Reportes</h1>
				<div className='w-full h-full bg-white'>
					<div className="flex w-full md:w-1/3 mx-auto items-center justify-center p-5">
						<Datepicker
							i18n={"es"}
							primaryColor={"fuchsia"}
							value={dateValue}
							onChange={handleValueChange}
						/>
					</div>
					<div className='w-full p-5'>
						<label htmlFor="fechaFin" className="block text-gray-600 uppercase font-bold">Agrupar por Tipo de Gasto</label>
						<Switch onChange={() => setCheck(!check)} checked={check} />
					</div>
					<div className='w-full mt-2 md:flex'>
						<div className='w-full md:w-1/2 mx-auto h-96 py-5 px-3 md:px-10 bg-slate-100'>
							<div className='w-full h-full bg-white'>
								<h4 className='text-black text-center uppercase'>Gastos</h4>
								{typeof gastos !== 'undefined' &&
									(
										<BarChartGraph data={gastos} />
									)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default Reporting