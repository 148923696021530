import { useState } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import AuthState from "./context/auth/authState";
import ContextStates from "./context/contextStates";
import Index from "./pages";
import ModalAlta from "./components/modalAlta";
import ModalVista from "./components/ModalVista";
import Gastos from "./pages/gastos";
import Reporting from "./pages/Reporting";

function App() {
	const [idUsuario, setIdUsuario] = useState(0)
	const [tipoUsrLogin, setTipoUsrLogin] = useState(0)
	const [roles, setRoles] = useState([])
	const [gastos, setGastos] = useState([])
	const [openAlta, setOpenAlta] = useState(false)
	const [formAlta, setFormAlta] = useState(0)
	const [idGasto, setIdGasto] = useState(0)
	const [tipoModal, setTipoModal] = useState(0)
	const [openVista, setOpenVista] = useState(false)
	const [gastoSelected, setGastoSelected] = useState({})
	const [itemSidebar, setItemSidebar] = useState(0)

	return (
		<AuthState>
			<ContextStates.Provider
				value={{
					idUsuario, setIdUsuario,
					tipoUsrLogin, setTipoUsrLogin,
					roles, setRoles,
					gastos, setGastos,
					openAlta, setOpenAlta,
					formAlta, setFormAlta,
					idGasto, setIdGasto,
					tipoModal, setTipoModal,
					openVista, setOpenVista,
					gastoSelected, setGastoSelected,
					itemSidebar, setItemSidebar
				}}
			>
				<BrowserRouter>
					<Routes>
						<Route path='/' element={<Index />} />
						<Route path='/gastos' element={<Gastos />} />
						<Route path='/reporting' element={<Reporting />} />
					</Routes>
					<ModalAlta />
					<ModalVista />
				</BrowserRouter>
			</ContextStates.Provider>
		</AuthState>
	);
}

export default App;
