import React, { useContext, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import authContext from '../context/auth/authContext';
import ContextStates from '../context/contextStates';
import SideBar from './SideBar';
import Login from '../pages/Login';
import axios from 'axios';
import { Url } from './Url';

const Layout = ({ children, pagina }) => {
	const AuthContext = useContext(authContext);
	const { usuarioAutenticado } = AuthContext;
	const mail = localStorage.getItem('sgst_m');
	const token = localStorage.getItem('sgst_tk');
	const { setRoles, setTipoUsrLogin, setIdUsuario } = useContext(ContextStates);

	useEffect(() => {
		usuarioAutenticado()
	}, []);

	useEffect(() => {
		const getRoles = async () => {
			if (mail) {
				const formData = new FormData()
				formData.append('mail', mail);
				formData.append('token', token);

				try {
					const response = await axios({
						url: Url + 'api/rolesusuario.php',
						method: 'POST',
						data: formData,
					})
					setRoles(response.data.results.roles)
					setTipoUsrLogin(response.data.results.tipousuario)
					setIdUsuario(response.data.results.idusuario)
				} catch (e) {
					console.log(e)
				}
			}
		}
		getRoles()
	}, [mail])

	return (
		<HelmetProvider>
			<Helmet>
				<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
				<link rel="manifest" href="/site.webmanifest" />
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
				<title>{pagina} - Mirx Software</title>
				<meta name="description" content="Empresa" />
				<meta property="og:title" content="Empresa" />
				<meta property="og:description" content="Empresa" />
				<meta property="og:image" content="https://mirxsoftware.com" />
				<meta property="og:url" content="https://mirxsoftware.com" />
				<meta property="og:site_name" content="Empresa" />
				<meta property="og:locale" content="es_AR" />
				<meta property="og:type" content="article" />
			</Helmet>
			{
				mail ? (
					<div className='flex'>
						<SideBar />
						{children}
					</div>
				) : (
					<Login />
				)
			}
		</HelmetProvider>
	)
}

export default Layout