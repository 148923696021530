import React, { useContext } from 'react'
import ContextStates from '../context/contextStates';
import { Url } from './Url'

const FViewGasto = () => {
    const { setOpenVista, gastoSelected } = useContext(ContextStates);
    return (
        <div className='mt-10 mb-10 px-10'>
            <p className='text-xl text-gray-600'><span className='font-semibold'>Descripción: </span>{gastoSelected.descripcion}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Tipo de Gasto: </span>{gastoSelected.tipogasto}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Importe: </span>{gastoSelected.importe}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Fecha: </span>{gastoSelected.fecha}</p>
            <div className='justify-center w-1/2 mx-auto'>
                {gastoSelected.imagen && (
                    <img className='w-full object-cover rounded-xl' src={Url + gastoSelected.imagen} />
                )}

            </div>
            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                <button
                    type="button"
                    className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenVista(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    )
}

export default FViewGasto