import { useState, useEffect, useContext } from 'react'
import ContextStates from '../context/contextStates'
import Switch from "react-switch";
import Datepicker from "react-tailwindcss-datepicker";
import { FaEye, FaPen, FaTrash } from 'react-icons/fa'
import axios from 'axios'
import { Url } from './Url'

const ListGastos = () => {
    const [check, setCheck] = useState(false)
    const { gastos, setGastos, setTipoModal, setOpenVista, gastoSelected, setGastoSelected } = useContext(ContextStates)
    const [dateValue, setDateValue] = useState({
        startDate: new Date(),
        endDate: new Date().setMonth(11)
    });

    useEffect(() => {
        const getGastos = async () => {
            let group = 0;
            if (check)
                group = 1;
            const formData = new FormData()
            formData.append('group', group);
            formData.append('fechainicio', dateValue.startDate)
            formData.append('fechafin', dateValue.endDate)
            try {
                const response = await axios({
                    url: Url + 'api/gastosList.php',
                    method: 'POST',
                    data: formData,
                })
                setGastos(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getGastos()

    }, [dateValue.startDate, dateValue.endDate, check])

    const handleValueChange = (newValue) => {
        //console.log("newValue:", newValue);
        setDateValue(newValue);
    }

    const asignarGasto = (element) => {
        setGastoSelected({
            id: element.id,
            descripcion: element.descripcion,
            tipogasto: element.tipogasto,
            importe: element.importe,
            fecha: element.fecha,
            imagen: element.imagen
        })
    }

    const verGasto = async (item) => {
        asignarGasto(item);
        setTipoModal(1)
        setOpenVista(true);
    }

    const editGasto = async (item) => {
        asignarGasto(item);
        setTipoModal(2)
        setOpenVista(true);
    }


    return (
        <div>
            <div className='w-1/2 p-5 mx-auto'>
                <p className='text-center text-xl text-gray-600'>Rango de Fechas</p>
                <Datepicker
                    i18n={"es"}
                    primaryColor={"fuchsia"}
                    value={dateValue}
                    onChange={handleValueChange}
                />
            </div>
            <div className='w-full p-5'>
                <label htmlFor="fechaFin" className="block text-gray-600 uppercase font-bold">Agrupar por Tipo de Gasto</label>
                <Switch onChange={() => setCheck(!check)} checked={check} />
            </div>
            <table className='table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
                <thead>
                    <tr className='uppercase bg-blue-400 text-white'>
                        <th className='border-gray-400 p-2'>Descripcion</th>
                        <th className='border-gray-400 p-2'>Tipo</th>
                        <th className='border-gray-400 p-2'>Importe</th>
                        <th className='border-gray-400 p-2'>Fecha</th>
                        <th className='border-gray-400 p-2'>Acciones</th>
                    </tr>
                </thead>
                <tbody >
                    {
                        typeof gastos !== 'undefined' ? (
                            gastos.map((item, index) => (

                                <tr
                                    key={item.id}
                                    className={`hover:cursor-pointer border-b border-gray-200  ${index % 2 === 0 ? 'bg-white hover:bg-slate-200' : 'bg-slate-100 hover:bg-slate-200'}`}
                                >
                                    <td className='border-gray-400 p-2 text-center'>{item.descripcion}</td>
                                    <td className='border-gray-400 p-2 text-center'>{item.tipogasto}</td>
                                    <td className='border-gray-400 p-2 text-center'>{item.importe}</td>
                                    <td className='border-gray-400 p-2 text-center'>{item.fecha}</td>
                                    <td className='border-gray-400 p-2 text-center'>
                                        <button
                                            className='bg-indigo-500 p-2 rounded-md text-white'
                                            onClick={() => verGasto(item)}
                                        >
                                            <FaEye />
                                        </button>
                                        <button
                                            className='bg-red-500 ml-2 p-2 rounded-md text-white'
                                            onClick={() => editGasto(item)}
                                        >
                                            <FaPen />
                                        </button>
                                    </td>
                                </tr>

                            ))
                        ) : (null)
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ListGastos